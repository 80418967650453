import { ReactElement, useEffect } from "react";
import { Button, VStack, useTheme } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout } from "../../components/atoms/Layout";
import { ENABLED_ENGINES } from "../../environments";
import { useAtom } from "jotai";
import {
  accessTokenInfoAtom,
  allEngineEnabledAtom,
  engineTypesAtom,
} from "../../store";

export function getSelectableEngineList(
  engineTypes: object,
  allEngineEnabled: boolean
): string[] {
  const Cognitive_enable =
    ENABLED_ENGINES.includes("Cognitive") && allEngineEnabled;
  const cog_enable =
    ENABLED_ENGINES.includes("cog") &&
    (allEngineEnabled || "cog" in engineTypes || "co1" in engineTypes);
  const co1_enable =
    ENABLED_ENGINES.includes("co1") &&
    (allEngineEnabled || "co1" in engineTypes);
  const np1_enable =
    ENABLED_ENGINES.includes("np1") &&
    (allEngineEnabled || "np1" in engineTypes);
  const dysphagia_enable =
    ENABLED_ENGINES.includes("dysphagia") &&
    (allEngineEnabled || "dysphagia" in engineTypes);
  const mi2_enable =
    ENABLED_ENGINES.includes("Mi1_Mi2") &&
    (allEngineEnabled || "mi1" in engineTypes || "mi2" in engineTypes);
  const br1_enable =
    ENABLED_ENGINES.includes("br1") &&
    (allEngineEnabled || "br1" in engineTypes);
  const UIX_enable = ENABLED_ENGINES.includes("UIX") && allEngineEnabled;
  const Co1Demo_enable =
    ENABLED_ENGINES.includes("Co1Demo") &&
    (allEngineEnabled || "co1" in engineTypes);
  const NTT_enable =
    ENABLED_ENGINES.includes("NTT") &&
    (allEngineEnabled || "mi1" in engineTypes);
  const sl1_enable =
    ENABLED_ENGINES.includes("sl1") &&
    (allEngineEnabled || "sl1" in engineTypes);

  const ret = [] as string[];
  if (Cognitive_enable) ret.push("Cognitive");
  if (cog_enable) ret.push("cog");
  if (co1_enable) ret.push("co1");
  if (np1_enable) ret.push("np1");
  if (dysphagia_enable) ret.push("dysphagia");
  if (mi2_enable) ret.push("Mi1_Mi2");
  if (br1_enable) ret.push("br1");
  if (UIX_enable) ret.push("UIX");
  if (Co1Demo_enable) ret.push("Co1Demo");
  if (NTT_enable) ret.push("NTT");
  if (sl1_enable) ret.push("sl1");
  return ret;
}

export function Engines(): ReactElement {
  const { t } = useTranslation();

  const [tokenInfo] = useAtom(accessTokenInfoAtom);
  if (tokenInfo === null) throw new Error("no token");

  const [engineTypes] = useAtom(engineTypesAtom);
  const [allEngineEnabled] = useAtom(allEngineEnabledAtom);
  const url_list = getSelectableEngineList(engineTypes, allEngineEnabled);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  return (
    <Layout h="full">
      <VStack justify="center" minH="full" gap={12}>
        {url_list.includes("cog") && (
          <Button
            as={Link}
            to="../cog/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.cog")}
          </Button>
        )}
        {url_list.includes("co1") && (
          <Button
            as={Link}
            to="../co1/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.co1")}
          </Button>
        )}
        {url_list.includes("np1") && (
          <Button
            as={Link}
            to="../np1/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.np1")}
          </Button>
        )}
        {url_list.includes("Cognitive") && (
          <Button
            as={Link}
            to="../Cognitive/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.Cognitive")}
          </Button>
        )}
        {url_list.includes("Mi1_Mi2") && (
          <Button
            as={Link}
            to="../Mi1_Mi2/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.Mi1_Mi2")}
          </Button>
        )}
        {url_list.includes("mi2") && (
          <Button
            as={Link}
            to="../mi2/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.mi2")}
          </Button>
        )}
        {url_list.includes("dysphagia") && (
          <Button
            as={Link}
            to="../dysphagia/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.dysphagia")}
          </Button>
        )}
        {url_list.includes("br1") && (
          <Button
            as={Link}
            to="../br1/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.br1")}
          </Button>
        )}
        {url_list.includes("sl1") && (
          <Button
            as={Link}
            to="../sl1/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.sl1")}
          </Button>
        )}
        {url_list.includes("UIX") && (
          <Button
            as={Link}
            to="../UIX/questionnaires"
            width="full"
            variant="primary"
            size="lg"
          >
            {t("EngineSelection.uix")}
          </Button>
        )}
        {url_list.includes("Co1Demo") && (
          <Button
            as={Link}
            to="../Co1Demo/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.Co1Demo")}
          </Button>
        )}
        {url_list.includes("NTT") && (
          <Button
            as={Link}
            to="../NTT/questionnaires"
            width="full"
            variant="btn_primary"
            size="lg"
          >
            {t("EngineSelection.ntt")}
          </Button>
        )}
      </VStack>
    </Layout>
  );
}
