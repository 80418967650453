import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { Mi1Engine } from "./engines/mi1Engine";
import { CogEngine } from "./engines/cogEngine";
import { Co1Engine } from "./engines/co1Engine";
import { Np1Engine } from "./engines/np1Engine";
import { DysphagiaEngine } from "./engines/dysphagiaEngine";
import { Mi2Engine } from "./engines/mi2Engine";
import { Br1Engine } from "./engines/br1Engine";
import {
  DEFAULT_DETECT_VOLUME,
  TOKEN_INFO_SESSION_STORAGE_KEY,
} from "./constants";
import { AudioRecorder } from "./utils/audioRecorder/audioRecorder";
import workletUrl from "./assets/js/RecorderProcessor.js?url";
import { themeType } from "./types";
import { Sl1Engine } from "./engines/sl1Engine";

const DETECT_VOLUME_LOCALSTORAGE_KEY = "detectVolume";

export const detectVolumeAtom = atomWithStorage(
  DETECT_VOLUME_LOCALSTORAGE_KEY,
  DEFAULT_DETECT_VOLUME
);

export const detectedVolumeByPhraseVisualizerAtom = atom(false);

export const themeColorAtom = atomWithStorage<themeType>(
  "themeColor",
  "sakura_light"
);

export const cognitiveEnginesAtom = atom([
  new CogEngine(),
  new Co1Engine(),
  new Np1Engine(),
]);

export const cogEnginesAtom = atom([
  new Mi1Engine(),
  new Mi2Engine(),
  new CogEngine(),
]);

export const co1EnginesAtom = atom([new Co1Engine()]);

export const np1EnginesAtom = atom([new Np1Engine()]);

export const mi1_Mi2EnginesAtom = atom([new Mi1Engine(), new Mi2Engine()]);

export const mi1EnginesAtom = atom([new Mi1Engine()]);

export const mi2EnginesAtom = atom([new Mi2Engine()]);

export const dysphagiaEnginesAtom = atom([new DysphagiaEngine()]);

export const br1EnginesAtom = atom([new Br1Engine()]);

export const uixEnginesAtom = atom([
  new Mi1Engine(),
  new Mi2Engine(),
  new Co1Engine(),
  new Br1Engine(),
]);

export const co1DemoEnginesAtom = atom([
  new Co1Engine({ _analysisType: "Co1Demo" }),
]);

export const sl1EnginesAtom = atom([new Sl1Engine({ _analysisType: "sl1" })]);

export type QuestionnaireAnswers = {
  state: "idle" | "skipped" | "responded";
  name: string;
  answers: Record<string, string>;
};
export const questionnaireAtom = atom<QuestionnaireAnswers>({
  state: "idle",
  name: "",
  answers: {},
});

export type AccessTokenInfo = {
  access_token: string;
  expired_at: string;
};
const accessTokenStorage = createJSONStorage<AccessTokenInfo | null>(
  () => sessionStorage
);
export const accessTokenInfoAtom = atomWithStorage<AccessTokenInfo | null>(
  TOKEN_INFO_SESSION_STORAGE_KEY,
  null,
  accessTokenStorage
);

export const audioRecorder = new AudioRecorder(workletUrl);

export type EmployeeInfoType = {
  company_id: string;
  employee_name: string;
  employee_id: string;
};

export const employeeInfoInitial = {
  company_id: "",
  employee_name: "",
  employee_id: "",
};

export const employeeInfoAtom = atom<EmployeeInfoType>(employeeInfoInitial);

const engineTypesStorage = createJSONStorage<object>(() => sessionStorage);
export const engineTypesAtom = atomWithStorage<object>(
  "engineTypes",
  [],
  engineTypesStorage
);
const allEngineEnabledStorage = createJSONStorage<boolean>(
  () => sessionStorage
);
export const allEngineEnabledAtom = atomWithStorage<boolean>(
  "allEngineEnable",
  false,
  allEngineEnabledStorage
);
