import { ReactElement, useContext, useEffect, useState } from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { ImageContext } from "../atoms/ImageProvider";
import { useTranslation } from "react-i18next";
import Co1ResultAImgUrl from "../../assets/images/co1/co1_result_a.png";
import Co1ResultBImgUrl from "../../assets/images/co1/co1_result_b.png";
import Co1ResultCImgUrl from "../../assets/images/co1/co1_result_c.png";

export function ResultCo1Demo(props: {
  title: string;
  co1_result: string;
}): ReactElement {
  const { imageSrc, preloadImage } = useContext(ImageContext);
  const [resultComment, setResultComment] = useState("");
  const { t } = useTranslation();
  useEffect(() => {
    if (!imageSrc || !imageSrc.co1Results[props.co1_result]) {
      preloadImage &&
        preloadImage("co1Results", {
          A: Co1ResultAImgUrl,
          B: Co1ResultBImgUrl,
          C: Co1ResultCImgUrl,
        });
    }
    switch (props.co1_result) {
      case "A":
        setResultComment(t("Co1DemoResult.resultA_comment"));
        break;
      case "B":
        setResultComment(t("Co1DemoResult.resultB_comment"));
        break;
      case "C":
        setResultComment(t("Co1DemoResult.resultC_comment"));
        break;
    }
  }, [props.co1_result, setResultComment, t, imageSrc, preloadImage]);
  return (
    <Box mb={10} color="#555555">
      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Text fontSize="2xl">{resultComment}</Text>
        <Image
          src={imageSrc ? imageSrc.co1Results[props.co1_result] : undefined}
          boxSize="80%"
        />
        <Box width="80%">
          <Text fontSize="sm">{t("Co1DemoResult.note1")}</Text>
          <Text fontSize="sm">{t("Co1DemoResult.note2")}</Text>
        </Box>
      </Flex>
    </Box>
  );
}
