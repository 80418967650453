import { ReactElement, useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { Button, Text, useTheme } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { appAxios } from "../../../../utils/appAxios";
import { Layout } from "../../../../components/atoms/Layout";
import {
  useAssertEngineTypeFromPathParam,
  useGetAnalysisEngines,
} from "../../../../utils/selectAnalysisEngine";
import { APP_TYPE } from "../../../../environments";
import {
  ResultUIX,
  UixResultType,
} from "../../../../components/molecules/ResultUIX";
import { isCanGetAverage } from "../../../../engines/baseAnalysisEngine";
import { ApiEngineType, MeanResult } from "../../../../types";
import { employeeInfoAtom } from "../../../../store";
import { useAtom } from "jotai";
import {
  CognitiveResultType,
  ResultCognitive,
} from "../../../../components/molecules/ResultCognitive";

export function Result(): ReactElement {
  const { t } = useTranslation();
  const engineType = useAssertEngineTypeFromPathParam();
  const analysisEngines = useGetAnalysisEngines(engineType);
  const [employeeInfo] = useAtom(employeeInfoAtom);

  const [recordedAt, setRecordedAt] = useState("");
  const [engineResultElements, setEngineResultElements] = useState<
    ReactElement[] | null
  >(null);

  const { fileId } = useParams();
  useEffect(() => {
    if (!fileId) return;

    if (APP_TYPE === "uix") {
      const resultPromises = analysisEngines.map((engine) =>
        engine.getResult(fileId)
      );

      const averagePromises = analysisEngines.map((engine) => {
        if (isCanGetAverage(engine)) {
          const company_id = employeeInfo.company_id;
          return engine.getAverage(company_id);
        }
        return Promise.resolve(null);
      });

      Promise.all([...resultPromises, ...averagePromises]).then(
        (allResults) => {
          const renderingResults = allResults.slice(0, analysisEngines.length);
          const averageResults = allResults.slice(analysisEngines.length);

          const resultsMap: Map<ApiEngineType, UixResultType> = new Map();
          for (let i = 0; i < analysisEngines.length; i++) {
            const engineType = analysisEngines[i]._engineType;
            resultsMap.set(engineType, renderingResults[i] as UixResultType);
          }

          const averageMap: Map<ApiEngineType, MeanResult> = new Map();
          for (let i = 0; i < analysisEngines.length; i++) {
            if ("getAverage" in analysisEngines[i]) {
              const engineType = analysisEngines[i]._engineType;
              averageMap.set(engineType, averageResults[i] as MeanResult);
            }
          }

          setEngineResultElements([
            <ResultUIX
              key=""
              resultsMap={resultsMap}
              averageMap={averageMap}
            />,
          ]);
        }
      );
    } else if (engineType === "Cognitive") {
      const resultPromises = analysisEngines.map((engine) =>
        engine.getResult(fileId)
      );
      Promise.all(resultPromises).then((allResults) => {
        const renderingResults = allResults.slice(0, analysisEngines.length);
        const resultsMap: Map<ApiEngineType, CognitiveResultType> = new Map();
        for (let i = 0; i < analysisEngines.length; i++) {
          const engineType = analysisEngines[i]._engineType;
          resultsMap.set(
            engineType,
            renderingResults[i] as CognitiveResultType
          );
        }
        setEngineResultElements([
          <ResultCognitive key="" resultsMap={resultsMap} />,
        ]);
      });
    } else {
      const renderingPromises = analysisEngines.map((engine, engineIndex) =>
        engine.renderResult(engineIndex, fileId, t)
      );

      Promise.all(renderingPromises).then((renderingResults) => {
        setEngineResultElements(renderingResults);
      });
    }
  }, [fileId, t, analysisEngines, employeeInfo.company_id, engineType]);

  useEffect(() => {
    appAxios
      .get(`/voice`, { params: { file_id: fileId } })
      .then((voiceResponse) => {
        //現状ではrecorded_atは日本時間しかないのでtoLocaleString("ja")にしているが、
        //サーバ側でvoiceResponseでlocaleが指定されれば、それに応じた時刻表示を行う。
        const recorded_at = new Date(voiceResponse.data.recorded_at);
        setRecordedAt(recorded_at.toLocaleString("ja"));
      })
      .catch(() => setRecordedAt(t("Result.Error.RecordedAt")));
  }, [fileId, t]);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  if (!fileId) {
    return <Navigate to="/404" replace />;
  } else if (engineResultElements !== null) {
    return (
      <Layout>
        <Layout.Title
          color="#555555"
          subTitle={
            <Text
              as="small"
              fontWeight="bold"
              fontSize="14px"
              paddingY="6px"
              color="text.main_text_lv1"
            >
              {recordedAt}
            </Text>
          }
        >
          {t("Result.analysis_results")}
        </Layout.Title>
        {engineResultElements}
        <Button
          as={Link}
          to="../../../../home"
          size="lg"
          variant="btn_primary"
          width="full"
        >
          HOME
        </Button>
      </Layout>
    );
  } else {
    return <></>;
  }
}
