import { RecordedData } from "../../types";
import { ReactElement } from "react";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import { encodeWav } from "../../utils/wav";

export function DebugAudioPlayer(props: {
  allRecordedData: RecordedData[];
  title?: string | undefined;
}): ReactElement {
  return (
    <Popover placement="top-end">
      <PopoverTrigger>
        <Button position="fixed" bottom={5} right={5}>
          {props.title || "Debug"}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Debug Information</PopoverHeader>
        <PopoverBody>
          <VStack spacing={5} align="start" maxHeight="80vh" overflow="auto">
            {props.allRecordedData.map((data, dataIndex) => {
              const wav = encodeWav(data.data, data.samplingRate);
              const phraseType =
                data.co1VoiceType ||
                data.dysphagiaVoiceTypes ||
                data.br1VoiceType ||
                data.sl1VoiceType;
              const typeText = phraseType ? `, ${phraseType}` : "";
              const metaInfo = `${data.samplingRate}${typeText}`;
              return (
                <div key={dataIndex}>
                  <Text>
                    {dataIndex}: ({metaInfo})
                  </Text>
                  <Text whiteSpace="pre-wrap">{data.phrase}</Text>
                  <audio src={URL.createObjectURL(wav)} controls />
                </div>
              );
            })}
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
